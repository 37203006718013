import {
  Row,
  Col,
  Table,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Form,
  Card,
  Accordion,
} from 'react-bootstrap';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import _debounce from 'lodash/debounce';
import _last from 'lodash/last';
import _uniq from 'lodash/uniq';

import Confirm from '../components/confirm';
import Title from '../components/title';
import Badge from '../components/badge';
import FileTypeIcon from '../components/file_type_icon';
import Loader from '../components/loader';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { filterDo } from '../actions/filter_actions';

import documentListQuery from '../queries/document_list_query';
import documentCategoryListQuery from '../queries/document_category_list_query';
import documentDeleteMutation from '../mutations/document_delete_mutation';

import { queriesReady, getExport, getTaggingsNameString } from '../lib/utils';

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  componentDidMount() {
    this.delayedHandleFilter = _debounce((e) => {
      if (e.target.value.length > 1 || e.target.value === '') {
        this.props.filterDo({ type: 'document', q: e.target.value });
      }
    }, 400);
    if (this.props.filterQ) {
      this.delayedHandleFilter({ target: { value: this.props.filterQ } });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filterQ && this.isLoading(this.props) && this.isLoaded(nextProps)) {
      this.delayedHandleFilter({ target: { value: nextProps.filterQ } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleFilter.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const exportType = e.target.getAttribute('data-export-type');
    const displayName = e.target.getAttribute('data-display-name');
    getExport(reportName, {}, null, exportType, displayName)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.documentListQuery,
      testProps.documentCategoryListQuery
    );
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const documentId = _last(e.currentTarget.id.split('-'));
    this.props
      .documentDeleteMutation({
        variables: {
          id: documentId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Document delete');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  handleFilter(e) {
    e.persist();
    this.delayedHandleFilter(e);
  }

  getDocumentCategoryAncestryName(documentCategory) {
    const { rgt, lft } = documentCategory;
    return this.props.documentCategoryListQuery.data
      .filter((dc) => dc.lft <= lft && dc.rgt >= rgt)
      .sort((a, b) => a.lft - b.lft)
      .map((dc) => dc.name)
      .join(' | ');
  }

  getDocumentCategoryOptions() {
    let filterCategoryIds;
    if (this.props.filterQ) {
      filterCategoryIds = this.props.documentListQuery.data
        .filter((d) => this.props.filterIds.includes(d.id))
        .map((d) => d.document_category_id);
    } else {
      filterCategoryIds = this.props.documentListQuery.data.map(
        (d) => d.document_category_id
      );
    }
    filterCategoryIds = _uniq(filterCategoryIds);
    const options = this.props.documentCategoryListQuery.data
      .filter((dc) => filterCategoryIds.includes(dc.id))
      .map((documentCategory) => {
        const { id } = documentCategory;
        return {
          id,
          name: this.getDocumentCategoryAncestryName(documentCategory),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    return [
      ...options,
      ...(filterCategoryIds.includes(null) ? [{ id: 0, name: 'No Category' }] : []),
    ];
  }

  renderOverlay() {
    if (this.props.filtering || this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderTable(id) {
    const filtered = this.props.filterQ;
    return (
      <Table hover responsive striped>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Id</th>
            <th>Description</th>
            <th>Content Type</th>
            <th>Tags</th>
            <th className="text-right" style={{ width: '70px' }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.documentListQuery.data
            .filter(
              (model) =>
                (filtered ? this.props.filterIds.includes(model.id) : model) &&
                ((!model.document_category_id && id === 0) ||
                  model.document_category_id === id)
            )
            .map((model) => {
              const {
                id: documentId,
                description,
                document_file_name: documentFileName,
                document_content_type: documentContentType,
                taggings,
              } = model;
              const documentFileExtension = documentFileName
                ? documentFileName.split('.').pop()
                : 'unknown';
              return (
                <tr key={documentId}>
                  <td>
                    <FileTypeIcon size="x2" extension={documentFileExtension} />
                  </td>
                  <td>{documentId}</td>
                  <td>{description}</td>
                  <td>{documentContentType}</td>
                  <td>{getTaggingsNameString(taggings)}</td>
                  <td className="text-right">
                    {this.props.currentContact['manager?'] && (
                      <LinkContainer to={`/documents/${documentId}/edit`}>
                        <Button variant="link">edit</Button>
                      </LinkContainer>
                    )}
                    <Button
                      variant="link"
                      data-report-name={`documents/${documentId}`}
                      data-display-name={documentFileName}
                      data-export-type={documentFileExtension}
                      onClick={this.getExport}
                    >
                      {documentFileExtension === 'pdf' ? 'view' : 'download'}
                    </Button>
                    {this.props.currentContact['manager?'] && (
                      <Confirm
                        confirmId={`document-delete-${documentId}`}
                        onConfirm={this.handleDeleteClick}
                        title="Delete Document"
                        body="Are you sure you want to delete this document"
                        confirmText="Confirm"
                      >
                        <Button variant="link">delete</Button>
                      </Confirm>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }

  renderData() {
    if (this.isLoaded()) {
      const filtered = this.props.filterQ;
      return (
        <>
          <Row className="my-4">
            <Col sm={12}>
              <Title list>Documents</Title>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12} className="d-flex justify-content-end">
              <Form.Control
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  width: '200px',
                }}
                type="text"
                placeholder="filter..."
                defaultValue={this.props.filterQ}
                onChange={this.handleFilter}
              />
              <Badge filtered={filtered}>
                {filtered
                  ? this.props.filterIds.length
                  : this.props.documentListQuery.data.length}
              </Badge>
              <ButtonToolbar style={{ height: 'calc(1.5em + 0.75rem + 2px)' }}>
                <ButtonGroup>
                  <LinkContainer to="/documents/new">
                    <Button variant="primary">Add a Document</Button>
                  </LinkContainer>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {this.getDocumentCategoryOptions().map((documentCategory) => {
                const { id, name } = documentCategory;
                return (
                  <Accordion defaultActiveKey="0" key={id} className="my-4">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey={id}>
                        <Card.Title>{name}</Card.Title>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={id}>
                        <Card.Body>{this.renderTable(id)}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              })}
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

DocumentList.propTypes = {
  filterIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  filtering: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const filter = state.filters.document;
  return {
    filterIds: filter.ids,
    filterQ: filter.q,
    filtering: filter.filtering,
    currentContact: state.currentContact,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(documentDeleteMutation, {
    name: 'documentDeleteMutation',
  }),
  graphql(documentListQuery, {
    name: 'documentListQuery',
  }),
  graphql(documentCategoryListQuery, {
    name: 'documentCategoryListQuery',
  }),
  connect(mapStateToProps, {
    filterDo,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  })
)(DocumentList);
