import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Field } from 'redux-form';

import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import get from 'lodash/get';
import has from 'lodash/has';
import pick from 'lodash/pick';
import slice from 'lodash/slice';

import Glyphicon from '../glyphicon';
import LocationFormLocationImageImageField from './location_form_location_image_image_field';

class LocationFormLocationImageFieldArray extends Component {
  constructor(props) {
    super(props);
    this._handleAddButtonClick = this._handleAddButtonClick.bind(this);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
    this._handlePromoteClick = this._handlePromoteClick.bind(this);
    this._handleDemoteClick = this._handleDemoteClick.bind(this);
  }

  _handleAddButtonClick() {
    this.props.locationImages.fields.push({
      imageMediumUrl:
        'https://s3-ap-southeast-2.amazonaws.com/saam2-api-attachments/missing/location_images/images/medium/missing.jpg',
    });
  }

  _handleDeleteClick(index) {
    const locationImageValues = get(this.props, [
      'formValues',
      this.props.locationImages.fields.name,
      index,
    ]);
    if (get(locationImageValues, 'id')) {
      this.props.change(
        [this.props.locationImages.fields.name, index, '_destroy'].join('.'),
        true
      );
    } else {
      this.props.locationImages.fields.remove(index);
    }
  }

  _handlePromoteClick(index) {
    const candidateLocationImagesAttributes = slice(
      get(this.props, 'formValues.location_images_attributes'),
      0,
      index
    );
    const swapIndex = findLastIndex(
      candidateLocationImagesAttributes,
      (li) => !has(li, '_destroy')
    );
    this.props.locationImages.fields.swap(swapIndex, index);
  }

  _handleDemoteClick(index) {
    const candidateLocationImagesAttributes = slice(
      get(this.props, 'formValues.location_images_attributes'),
      index + 1
    );
    const swapIndex = findIndex(
      candidateLocationImagesAttributes,
      (li) => !has(li, '_destroy')
    );
    this.props.locationImages.fields.swap(index, index + swapIndex + 1);
  }

  _renderList() {
    const formKeys = ['id', 'image'];
    const undeletedLength = get(
      this.props,
      'formValues.location_images_attributes'
    ).filter((lia) => !has(lia, '_destroy')).length;
    let undeletedIndex = -1;
    return (
      <Col sm={12}>
        <Row>
          {this.props.locationImages.fields.map((field, index) => {
            const values = get(this.props, `formValues.${field}`, {});
            if (!values._destroy) {
              undeletedIndex += 1;
              const fieldName = `${field}.image`;
              return (
                <Field
                  key={`li-${index}`}
                  index={index}
                  undeletedIndex={undeletedIndex}
                  parentField={field}
                  name={fieldName}
                  component={LocationFormLocationImageImageField}
                  formValues={pick(values, formKeys)}
                  handleDeleteClick={this._handleDeleteClick}
                  handlePromoteClick={this._handlePromoteClick}
                  handleDemoteClick={this._handleDemoteClick}
                  promoteDisabled={undeletedIndex === 0}
                  demoteDisabled={undeletedIndex === undeletedLength - 1}
                  locationImageUrl={
                    get(this.props, 'locationImageUrls', {})[get(values, 'id')]
                  }
                />
              );
            }
          })}
        </Row>
      </Col>
    );
  }

  render() {
    return (
      <Row>
        {this._renderList()}
        <Col sm={12}>
          <Button type="button" variant="dark" onClick={this._handleAddButtonClick}>
            <Glyphicon glyph="plus" />
            Add Image
          </Button>
        </Col>
      </Row>
    );
  }
}

export default LocationFormLocationImageFieldArray;
