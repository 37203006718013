/* eslint-disable react/jsx-props-no-spreading, react/no-children-prop */
import { Form } from 'react-bootstrap';

import InputField from './input_field';
import { isInvalid, validationText } from './helpers';
import HelpBlock from './help_block';

const CheckboxInputField = (props) => {
  const {
    input,
    meta,
    children,
    size,
    labelWidth,
    inputWidth,
    inlineLabel,
    helpText,
    className,
  } = props;
  const invalid = isInvalid(meta);
  return (
    <InputField
      {...(!inlineLabel && { children })}
      size={size}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      innerContent={
        <Form.Check className={className}>
          <Form.Check.Input
            type="checkbox"
            isInvalid={invalid}
            {...input}
            checked={input.value}
          />
          {inlineLabel && (
            <Form.Check.Label className={`py-0 col-form-label-${size}`}>
              {children}
            </Form.Check.Label>
          )}
          {invalid && (
            <Form.Control.Feedback type="invalid">
              {validationText(meta, helpText)}
            </Form.Control.Feedback>
          )}
          {!invalid && helpText && <HelpBlock>{helpText}</HelpBlock>}
        </Form.Check>
      }
    />
  );
};

CheckboxInputField.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 9,
  inlineLabel: true,
  className: '',
};

export default CheckboxInputField;
