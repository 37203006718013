/* eslint-disable react/jsx-props-no-spreading */
import { useState, useCallback, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

import last from 'lodash/last';
import get from 'lodash/get';

import Confirm from '../confirm';
import Glyphicon from '../glyphicon';
import InputField from './input_field';
import { isInvalid, validationText } from './helpers';
import HelpBlock from './help_block';
import FileTypeIcon from '../file_type_icon';

const DocumentFields = (props) => {
  const {
    index,
    documents_attributes: {
      [index]: { document, description },
    },
    formValues: { id, document_file_name: documentFileName },
    labelWidth,
    inputWidth,
    size,
    handleDelete: parentHandleDelete,
    helpText,
  } = props;

  const { input: descriptionInput, meta: descriptionMeta } = description;
  const { input: documentInput, meta: documentMeta } = document;

  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');

  const documentInvalid = isInvalid(documentMeta);

  useEffect(() => {
    if (documentFileName) {
      setFileName(documentFileName);
      const fileNameParts = documentFileName.split('.');
      if (fileNameParts.length > 1) {
        setFileExtension(last(fileNameParts).toLowerCase().replace('jpeg', 'jpg'));
      } else {
        setFileExtension('unknown');
      }
    } else {
      setFileName('New document');
      setFileExtension('unknown');
    }
  }, [documentFileName]);

  const handleDelete = () => {
    if (parentHandleDelete) {
      parentHandleDelete(index, id);
    }
  };

  const handleFileChange = useCallback(
    (e) => {
      const file = get(e, 'target.files.0');
      if (file) {
        const newFileName = get(file, 'name');
        setFileName(newFileName);
        const fileParts = newFileName.split('.');
        if (fileParts.length > 1) {
          setFileExtension(last(fileParts).toLowerCase().replace('jpeg', 'jpg'));
        } else {
          setFileExtension('unknown');
        }
        documentInput.onChange(file);
      }
    },
    [documentInput]
  );

  return (
    <Col sm={{ span: inputWidth, offset: labelWidth }}>
      <fieldset>
        <legend>
          <small>{fileName || 'New document'}</small>
        </legend>
        <Row>
          <Col sm={2}>
            <FileTypeIcon extension={fileExtension} size="x2" spacings="ml-4" />
          </Col>
          <Col sm={6}>
            <InputField
              type="text"
              size={size}
              labelWidth={labelWidth}
              inputWidth={inputWidth}
              meta={descriptionMeta}
              input={descriptionInput}
            >
              Description
            </InputField>
          </Col>
          <Col sm={4}>
            <div>
              <Confirm
                onConfirm={handleDelete}
                title="Delete Document"
                body={
                  id
                    ? 'Are you sure you want to delete this document? Note that while the document will be removed from the list, it will not be permanently deleted until you save this record.'
                    : 'Are you sure you want to delete this document?'
                }
                confirmText="Confirm"
              >
                <Button type="button" variant="dark" className="p-0">
                  <Glyphicon glyph="bin" />
                </Button>
              </Confirm>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 6, offset: 2 }}>
            <InputField
              size={size}
              labelWidth={labelWidth}
              inputWidth={inputWidth}
              input={{
                name: documentInput.name,
              }}
              innerContent={
                <>
                  <Form.Control
                    type="file"
                    value={undefined}
                    onChange={handleFileChange}
                  />
                  {documentInvalid && (
                    <Form.Control.Feedback type="invalid">
                      {validationText(documentMeta, helpText)}
                    </Form.Control.Feedback>
                  )}
                  {!documentInvalid && helpText && <HelpBlock>{helpText}</HelpBlock>}
                </>
              }
            >
              File
            </InputField>
          </Col>
        </Row>
      </fieldset>
    </Col>
  );
};

DocumentFields.defaultProps = {
  labelWidth: 3,
  inputWidth: 9,
  helpText: '',
  size: 'sm',
};

export default DocumentFields;
