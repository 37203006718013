import moment from 'moment';
import mime from 'mime-types';
import * as changeCase from 'change-case';

import cloneDeepWith from 'lodash/cloneDeepWith';
import compact from 'lodash/compact';
import defaultTo from 'lodash/defaultTo';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import Api from './api';

moment.updateLocale('en-nz');

function omitByNil(data) {
  return omitBy(data, isNil);
}

export function omitValues(data, blacklist = ['__typename']) {
  return omit(omitByNil(data), blacklist);
}

export function mapOmitValues(data, blacklist = ['__typename']) {
  return data.map((datum) => omitValues(datum, blacklist));
}

export function pickValues(data, whitelist = ['id']) {
  return pick(omitByNil(data), whitelist);
}

export function mapPickValues(data, whitelist = ['id']) {
  return data.map((datum) => pickValues(datum, whitelist));
}

export function queryReady(query, allowUndefinedQuery = false) {
  return !!((query && !query.loading && query.data) || (!query && allowUndefinedQuery));
}

export function queryJustReady(prevQuery, query) {
  return prevQuery.loading && queryReady(query);
}

export function queriesReady(...queries) {
  return queries.every((query) => queryReady(...[].concat(query)));
}

const integers = [
  // address
  // 'address_type',
  // booking
  'company_planned_reserve',
  'cruise_airspeed_sl_knots',
  'cruise_fuel_consumption_sl_kg',
  // contact, seat_assignment
  'weight',
  // engine_event
  'event_value',
  // fuel_tanker
  // 'capacity_unit',
  'capacity_value',
  'capacity_start',
  'service_radius',
  // location
  'display_format',
  // location_image, flight_segment, seat_assignment, aircraft_seat_seat_configuration
  'position',
  // phone_number
  // 'phone_number_type'
  // booking chargeables
  'pax',
];

const floats = [
  // admin_flight_record, hobb_record
  'flight_time',
  // flight_segment
  'airspeed_adj',
  'distance_adj',
  'end_location_landing_fee',
  'end_location_landing_fee_original',
  'start_fuel_adj',
  // fuel_bowser_fill, fuel_tanker_fill, oil_fill
  'quantity_value',
  // fuel_bowser_fill, fuel_tanker_fill, fuel_bowser
  'price',
  // hobb_record
  'start_hobb',
  'end_hobb',
  // location
  'airways_fee',
  'elevation',
  'landing_fee',
  'pal_frequency',
  // location, fuel_tanker
  'latitude',
  'longitude',
  // pilot_duty_record
  'duty_total',
  // pilot_flight_expense
  'total',
  'total_excl_gst',
  // pilot_flight_log_type
  'instrument_actual',
  'instrument_simulated',
  'instrument_ground',
  'sling',
  'longline',
  'fire',
  'survey',
  'nvg',
  'nvg_operations',
  // pilot_flight_log
  'command_practice_day',
  'command_practice_night',
  'copilot_day',
  'copilot_night',
  'dual_day',
  'dual_night',
  'log_total',
  'pilot_in_charge_day',
  'pilot_in_charge_night',
  // aircraft_seat
  'seat_longitudinal_arm',
  'seat_lateral_arm',
  'uninstalled_weight_reduction',
  'installed_weight_increase',
  // aircraft_hold
  'hold_longitudinal_arm',
  'hold_lateral_arm',
  'hold_maximum_weight',
  // aircraft_tank
  'tank_maximum_weight',
  // aircraft_tank_point
  'tank_point_longitudinal_arm',
  'tank_point_lateral_arm',
  'tank_point_weight',
  // wb_limit_point
  'wb_limit_arm',
  'wb_limit_weight',
  // hold_assignment
  'hold_assignment_weight',
];

function inputTyper(value, key) {
  if (key && !(typeof value === 'object')) {
    if (includes(floats, key)) {
      return defaultTo(parseFloat(value), null);
    }
    if (includes(integers, key)) {
      return defaultTo(parseInt(value, 10), null);
    }
    if (value === '') {
      return null;
    }
  }
  return undefined;
}

export function typeInput(data) {
  const typedData = cloneDeepWith(data, inputTyper);
  return typedData;
}

export function humanReportDate(start, end) {
  if (start && end) {
    if (moment(start).isAfter(end)) {
      return `${moment(start).format('MMMM, YYYY')} END DATE ERROR`;
    }
    if (moment(start).isSame(end, 'month')) {
      if (moment(start).isSame(end, 'day')) {
        return moment(start).format('Do MMMM, YYYY');
      }
      return `${moment(start).format('Do')} - ${moment(end).format('Do MMMM, YYYY')}`;
    }
    return `${moment(start).format('Do MMMM, YYYY')}  - ${moment(end).format(
      'Do MMMM, YYYY'
    )}`;
  }
  return 'eva..';
}

export function getExport(
  reportName,
  args = {},
  reportType = 'reports',
  exportType = 'pdf',
  displayName
) {
  const contentType = mime.lookup(exportType);
  return Api.get(
    `/export/${compact([reportType, reportName]).join('/')}.${exportType}`,
    mapKeys(args, (v, k) => changeCase.snakeCase(k)),
    { responseType: 'arraybuffer' }
  ).then((resp) => {
    const blob = new Blob([resp.data], { type: contentType });
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(blob);
    if (exportType === 'pdf') {
      link.target = '_blank';
    } else {
      link.download = displayName || reportName;
    }
    link.click();
  });
}

export function getTaggingsNameArray(taggings) {
  if (taggings && taggings.length > 0) {
    return taggings.map((tagging) => tagging.tag.name);
  }
  return [];
}

export function getTaggingsNameString(taggings) {
  if (taggings && taggings.length > 0) {
    return getTaggingsNameArray(taggings).join(', ');
  }
  return '';
}

function getFullPhoneNumber(phoneNumber) {
  const a = phoneNumber.country_code
    ? `+${phoneNumber.country_code.replace(/^\+/, '')}`
    : null;
  const b = phoneNumber.area_code
    ? `(0)${phoneNumber.area_code.replace(/^0/, '')}`
    : null;
  const y = phoneNumber.extension ? `x${phoneNumber.extension.replace(/^x/, '')}` : null;
  return [a, b, phoneNumber.phone_number, y].filter((part) => part).join(' ');
}

export function getPhoneNumberToString(phoneNumber) {
  if (phoneNumber && phoneNumber.id) {
    const z = phoneNumber.description ? `- ${phoneNumber.description}` : null;
    const q = phoneNumber.phone_number_type || 'No type';
    return [`${q}:`, getFullPhoneNumber(phoneNumber), z].filter((part) => part).join(' ');
  }
  return '';
}

function getFullAddress(address) {
  const streetNumber = [address.prefix, address.street_number, address.suffix]
    .filter((s) => s)
    .join('');
  const streetLine = [streetNumber, address.street].filter((s) => s).join(' ');
  const cityLine = [address.city, address.postcode].filter((s) => s).join(' ');
  return [streetLine, address.post_office_box, address.suburb, cityLine, address.region]
    .filter((s) => s)
    .join(', ');
}

export function getAddressToString(address) {
  if (address && address.id) {
    const z = address.description ? `- ${address.description}` : '';
    const q = address.address_type || 'No type';
    return [`${q}:`, getFullAddress(address), z].filter((part) => part).join(' ');
  }
  return '';
}
