import { Card, Col, Form, Button, Row } from 'react-bootstrap';
import { Form as FinalForm, Field } from 'react-final-form';

import { useEffect } from 'react';
// import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import qs from 'qs';

import get from 'lodash/get';

import {
  subscribeMutationObservable,
  unsubscribeMutationObservable,
} from '../lib/process_mutation_subscription';
import { authLoad } from '../actions/auth_actions';
import InputField from '../components/form/rff_input_field';
import Glyphicon from '../components/glyphicon';
import LoginLogo from '../images/login_logo_255X67.jpg';
import { loginFormValidator } from '../validators';

const useStyles = createUseStyles({
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default function Login() {
  const classes = useStyles();
  const isAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  // const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  let redirectPath = null;
  if (location.search) {
    redirectPath = get(
      qs.parse(location.search, { ignoreQueryPrefix: true }),
      'redirect',
      null
    );
  }

  useEffect(() => {
    if (isAuthenticated) {
      // navigate(redirectPath || '/bookings');
      navigate(redirectPath || '/locations');
    } else {
      // apolloClient.closeWSConnection();
      unsubscribeMutationObservable();
    }
  }, [isAuthenticated, navigate, redirectPath]);

  const handleFormSubmit = (data) => {
    dispatch(
      // authLoad(data.email, data.password, redirectPath || '/bookings', navigate)
      authLoad(data.email, data.password, redirectPath || '/locations', navigate)
      // authLoad(
      //   'gordon.king02@gmail.com',
      //   'xxxxxxxx',
      //   redirectPath || '/bookings',
      //   navigate
      // )
    ).then(() => {
      // apolloClient.openWSConnection();
      // console.log('login subscribe ');
      subscribeMutationObservable();
    });
  };

  return (
    <Card>
      <Card.Body>
        <input style={{ display: 'none' }} type="text" name="fakeemailremembered" />
        <input
          style={{ display: 'none' }}
          type="password"
          name="fakepasswordremembered"
        />
        <div style={{ marginBottom: '24px' }}>
          <img className={classes.img} src={LoginLogo} alt="advanced flight logo" />
        </div>
        <FinalForm onSubmit={handleFormSubmit} validate={loginFormValidator}>
          {({ handleSubmit, submitting, pristine, invalid }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Field type="text" name="email" component={InputField} helpText="&nbsp;">
                Email
              </Field>
              <Field
                type="password"
                name="password"
                component={InputField}
                helpText="&nbsp;"
              >
                Password
              </Field>
              <Form.Group as={Row}>
                <Col sm={12}>
                  <div className="float-right">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={pristine || invalid || submitting}
                    >
                      {isAuthenticating || submitting ? (
                        <Glyphicon glyph="refresh" spin />
                      ) : (
                        ''
                      )}{' '}
                      Submit
                    </Button>
                  </div>
                </Col>
              </Form.Group>
            </Form>
          )}
        </FinalForm>
      </Card.Body>
    </Card>
  );
}
