const Glyphicon = ({ glyph, style, spin = false }) => {
  const classNames = [
    'glyphicons',
    `glyphicons-${glyph}`,
    ...(spin ? ['glyphicons-spin'] : []),
  ]
    .map((c) => c)
    .join(' ');
  return <span className={classNames} style={{ verticalAlign: 'middle', ...style }} />;
};

export default Glyphicon;
