import {
  Row,
  Col,
  Table,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import { LinkContainer } from 'react-router-bootstrap';

import debounce from 'lodash/debounce';
import _identity from 'lodash/identity';
import last from 'lodash/last';
import _pick from 'lodash/pick';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { filterDo } from '../actions/filter_actions';

import Confirm from '../components/confirm';
import Loader from '../components/loader';
import Badge from '../components/badge';
import Title from '../components/title';

import { queriesReady, getExport } from '../lib/utils';

import locationDeleteMutation from '../mutations/location_delete_mutation';

import locationsListQuery from '../queries/location_list_query';

moment.updateLocale('en-nz');

class LocationList extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   filterRoleId: this.props.currentSettingsFilterRoleId,
    // };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    // this.handleFilterRoleIdChange = this.handleFilterRoleIdChange.bind(this);
  }

  componentDidMount() {
    this.delayedHandleFilter = debounce((e) => {
      if (e.target.value.length > 1 || e.target.value === '') {
        this.props.filterDo({ type: 'location', q: e.target.value });
      }
    }, 400);
    if (this.props.filterQ) {
      this.delayedHandleFilter({ target: { value: this.props.filterQ } });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filterQ && this.isLoading(this.props) && this.isLoaded(nextProps)) {
      this.delayedHandleFilter({ target: { value: nextProps.filterQ } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleFilter.cancel();
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const exportType = e.target.getAttribute('data-export-type');
    const args = {
      filters: this.props.filterIds,
    };
    getExport(reportName, _pick(args, _identity), null, exportType)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.locationListQuery);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const locationId = last(e.currentTarget.id.split('-'));
    this.props
      .locationDeleteMutation({
        variables: {
          id: locationId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Location delete');
      })
      .catch((res) => {
        this.props.mutationFailure(res);
      });
  }

  handleFilter(e) {
    e.persist();
    this.delayedHandleFilter(e);
  }

  // handleFilterRoleIdChange(e) {
  //   const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
  //   this.setState({
  //     filterRoleId: value,
  //   });
  //   this.props.currentSettingsSet({
  //     filterRoleId: value,
  //   });
  // }

  renderOverlay() {
    if (this.props.filtering || this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderTable() {
    const filtered = this.props.filterQ;
    return (
      <Table hover responsive striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Short Name</th>
            <th>Long Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.props.locationListQuery.data
            .filter((location) => {
              if (filtered && !this.props.filterIds.includes(location.id)) {
                return false;
              }
              return true;
            })
            .map((location) => {
              const { long_name: longName, id, short_name: shortName } = location;
              return (
                <tr key={id}>
                  <td>{id}</td>
                  <td>{shortName}</td>
                  <td>{longName}</td>
                  <td>
                    <Confirm
                      confirmId={`location-delete-${id}`}
                      onConfirm={this.handleDeleteClick}
                      title="Delete Location"
                      body={`Are you sure you want to delete ${longName}`}
                      confirmText="Confirm"
                    >
                      <Button variant="link" className="py-0">
                        delete
                      </Button>
                    </Confirm>
                    <LinkContainer to={`/locations/${id}/edit`}>
                      <Button variant="link" className="py-0">
                        edit
                      </Button>
                    </LinkContainer>
                    <LinkContainer to={`/locations/${id}`}>
                      <Button variant="link" className="py-0">
                        show
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }

  renderData() {
    if (this.isLoaded()) {
      const filtered = this.props.filterQ;
      return (
        <>
          <Row className="my-4">
            <Col sm={12}>
              <Title list>Locations</Title>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="d-flex justify-content-end">
              <Form.Control
                style={{
                  width: '200px',
                }}
                type="text"
                placeholder="filter..."
                defaultValue={this.props.filterQ}
                onChange={this.handleFilter}
              />
              <Badge filtered={filtered}>
                {filtered
                  ? this.props.filterIds.length
                  : this.props.locationListQuery.data.length}
              </Badge>
              <ButtonToolbar>
                <ButtonGroup className="mr-2">
                  <Button
                    variant="primary"
                    data-report-name="locations"
                    data-export-type="html"
                    onClick={this.getExport}
                  >
                    Print
                  </Button>
                  <Button
                    variant="primary"
                    data-report-name="locations"
                    data-export-type="pdf"
                    onClick={this.getExport}
                  >
                    PDF
                  </Button>
                  <Button
                    variant="primary"
                    data-report-name="locations"
                    data-export-type="csv"
                    onClick={this.getExport}
                  >
                    CSV
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <LinkContainer to="/locations/new">
                    <Button variant="primary">Add a Location</Button>
                  </LinkContainer>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          {this.renderTable()}
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

LocationList.propTypes = {
  filterIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  filtering: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const filter = state.filters.location;
  return {
    filterIds: filter.ids,
    filterQ: filter.q,
    filtering: filter.filtering,
    currentSettingsMutating: state.currentSettings.mutating,
    // currentSettingsFilterRoleId: state.currentSettings.filterRoleId,
  };
}

export default compose(
  graphql(locationDeleteMutation, {
    name: 'locationDeleteMutation',
  }),
  graphql(locationsListQuery, {
    name: 'locationListQuery',
  }),
  connect(mapStateToProps, {
    filterDo,
    mutationSuccess,
    mutationFailure,
    mutationSet,
    currentSettingsSet,
  })
)(LocationList);
