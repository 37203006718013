/* eslint-disable react/jsx-props-no-spreading */
import { Form } from 'react-bootstrap';

import InputField from './input_field';
import { isInvalid, validationText } from './helpers';
import HelpBlock from './help_block';
import TagList from '../tag_list';

const TagField = (props) => {
  const {
    input,
    meta,
    labelWidth,
    inputWidth,
    size,
    helpText,
    placeholderText,
    children,
    suggestions,
    handleTagCreate,
  } = props;

  const invalid = isInvalid(meta);

  return (
    <InputField
      size={size}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      innerContent={
        <>
          <TagList
            suggestions={suggestions}
            tagCollectionInput={input}
            onTagCreate={handleTagCreate}
            placeholderText={placeholderText}
          />
          {invalid && (
            <Form.Control.Feedback type="invalid">
              {validationText(meta, helpText)}
            </Form.Control.Feedback>
          )}
          {!invalid && helpText && <HelpBlock>{helpText}</HelpBlock>}
        </>
      }
    >
      {children}
    </InputField>
  );
};

TagField.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 9,
  helpText: '',
  placeholderText: '',
};

export default TagField;
