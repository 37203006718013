import { Component } from 'react';
import * as React from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import { Row, Col, Card, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import pickBy from 'lodash/pickBy';
import last from 'lodash/last';

import Confirm from '../components/confirm';
import DlHorizontal from '../components/dl_horizontal';
import Loader from '../components/loader';
import Title from '../components/title';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import fuelTypeListQuery from '../queries/fuel_type_list_query';
import fuelTankerQuery from '../queries/fuel_tanker_query';
import fuelTankerDeleteMutation from '../mutations/fuel_tanker_delete_mutation';

import { queriesReady } from '../lib/utils';

class FuelTankerShow extends React.Component {
  constructor(props) {
    super(props);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.fuelTypeListQuery, props.fuelTankerQuery);
  }

  _handleDeleteClick(e) {
    this.props.mutationSet(true);
    const fuel_tanker_id = last(e.currentTarget.id.split('-'));
    this.props
      .fuelTankerDeleteMutation({
        variables: {
          id: fuel_tanker_id,
        },
      })
      .then((res) => {
        this.props.mutationSuccess('Fuel Tanker delete');
        this.props.navigate('/fuel_tankers');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      const { id, name, fuel_type_id } = this.props.fuelTankerQuery.data;
      const fuel_type = this.props.fuelTypeListQuery.data.find(
        (ft) => ft.id === fuel_type_id
      );
      return (
        <>
          <Row className="my-4">
            <Col sm={12}>
              <Title show id={id}>
                Fuel Tanker
              </Title>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="d-flex justify-content-end">
              <ButtonToolbar>
                <ButtonGroup className="mx-1">
                  <Confirm
                    confirmId={`fuel-tanker-delete-${id}`}
                    onConfirm={this._handleDeleteClick}
                    title="Delete Fuel Tanker"
                    body="Are you sure you want to delete this Fuel Tanker?"
                    confirmText="Confirm"
                  >
                    <Button variant="primary">Delete</Button>
                  </Confirm>
                </ButtonGroup>
                <ButtonGroup className="mx-1">
                  <LinkContainer to="/fuel_tankers">
                    <Button variant="primary">All Fuel Tankers</Button>
                  </LinkContainer>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Card>
                    <Card.Header>Fuel Tanker Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal dt="Name" dd={name} />
                      <DlHorizontal dt="Fuel Type" dd={fuel_type.name} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      );
    }
  }

  render() {
    return (
      <>
        {this._renderOverlay()}
        {this._renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

FuelTankerShow = compose(
  graphql(fuelTankerDeleteMutation, {
    name: 'fuelTankerDeleteMutation',
  }),
  graphql(fuelTypeListQuery, {
    name: 'fuelTypeListQuery',
  }),
  graphql(fuelTankerQuery, {
    name: 'fuelTankerQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(FuelTankerShow);

export default connect(mapStateToProps, {
  mutationSuccess,
  mutationFailure,
  mutationSet,
})(FuelTankerShow);
