import { Row, Col, Button, OverlayTrigger, Tooltip, Badge, Form } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import get from 'lodash/get';
import setWith from 'lodash/setWith';
import includes from 'lodash/includes';
import without from 'lodash/without';
import forOwn from 'lodash/forOwn';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { currentSettingsSet } from '../actions/current_setting_actions';

import AircraftFlightTypePilot from '../components/aircraft_flight_type_pilot';
import Loader from '../components/loader';
import Title from '../components/title';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, queryJustReady, typeInput } from '../lib/utils';

import aircraftFlightTypePilotsMutation from '../mutations/aircraft_flight_type_pilot_create_mutation';

import aircraftFlightTypePilotMapQuery from '../queries/aircraft_flight_type_pilot_map_query';
import aircraftListQuery from '../queries/aircraft_list_query';
import contactItemListQuery from '../queries/contact_item_list_query';
import flightTypeListQuery from '../queries/flight_type_list_query';

class AuthorizationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstLoaded: false,
      isEditing: false,
      editTitle: '',
      aircraft: null,
      flightType: null,
      pilotIds: [],
      filterPilotId: this.props.currentSettingsReportPilotId,
      pilotChanges: {},
    };
    this.handleEditPilotClick = this.handleEditPilotClick.bind(this);
    this.handleEditPilotSubmit = this.handleEditPilotSubmit.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.handleDisplayPilotIdChange = this.handleDisplayPilotIdChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.isFirstLoaded && this.isLoaded(nextProps)) {
      this.setState({
        isFirstLoaded: true,
      });
    }
    if (
      queryJustReady(
        this.props.aircraftFlightTypePilotMapQuery,
        nextProps.aircraftFlightTypePilotMapQuery
      )
    ) {
      this.setState({
        pilotChanges: {},
      });
    }
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.aircraftFlightTypePilotMapQuery,
      testProps.aircraftListQuery,
      testProps.flightTypeListQuery,
      testProps.pilotListQuery
    );
  }

  handleDisplayPilotIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterPilotId: value,
    });
    this.props.currentSettingsSet({
      reportPilotId: value,
    });
  }

  handleEditClick(e) {
    const aircraftId = e.currentTarget.getAttribute('data-aircraft-id');
    const flightTypeId = e.currentTarget.getAttribute('data-flight-type-id');
    const pilotIds = JSON.parse(e.currentTarget.getAttribute('data-pilot-ids'));
    const aircraft = this.props.aircraftListQuery.data.find(
      (a) => a.id === parseInt(aircraftId, 10)
    );
    const flightType = this.props.flightTypeListQuery.data.find(
      (ft) => ft.id === parseInt(flightTypeId, 10)
    );

    this.setState({
      isEditing: true,
      editTitle: `Edit pilots for ${aircraft.registration_abbreviated} ${flightType.name}`,
      aircraft,
      flightType,
      pilotIds,
    });
  }

  handleEditCancel() {
    this.setState({
      isEditing: false,
      editTitle: '',
      aircraft: null,
      flightType: null,
      pilotIds: [],
    });
  }

  handleEditPilotClick(e) {
    const aircraftId = e.currentTarget.getAttribute('data-aircraft-id');
    const flightTypeId = e.currentTarget.getAttribute('data-flight-type-id');
    const pilotIds = JSON.parse(e.currentTarget.getAttribute('data-pilot-ids'));
    const { pilotChanges } = this.state;
    setWith(
      pilotChanges,
      [this.state.filterPilotId, aircraftId, flightTypeId],
      pilotIds,
      Object
    );
    this.setState({ pilotChanges });
  }

  handleEditPilotSubmit() {
    const changes = get(this.state.pilotChanges, [this.state.filterPilotId]);
    this.props.mutationSet(true);
    let pilotPromise;
    if (changes && Object.keys(changes).length > 0) {
      const promises = [];
      forOwn(changes, (flightTypes, aircraftId) => {
        forOwn(flightTypes, (pilotIds, flightTypeId) => {
          promises.push(
            this.props.aircraftFlightTypePilotsMutation({
              variables: {
                input: typeInput({
                  aircraft_id: aircraftId,
                  flight_type_id: flightTypeId,
                  pilot_ids: pilotIds,
                }),
              },
            })
          );
        });
      });
      pilotPromise = Promise.all(promises);
    } else {
      pilotPromise = Promise.resolve([]);
    }
    pilotPromise
      .then(() => {
        this.props.mutationSuccess('authorization');
        this.props.aircraftFlightTypePilotMapQuery.refetch();
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  handleEditSubmit(
    ids,
    aircraftId = this.state.aircraft.id,
    flightTypeId = this.state.flightType.id
  ) {
    this.props.mutationSet(true);
    const props = {
      aircraft_id: aircraftId,
      flight_type_id: flightTypeId,
      pilot_ids: ids,
    };
    this.props
      .aircraftFlightTypePilotsMutation({
        variables: {
          input: props,
        },
      })
      .then(() => {
        this.props.mutationSuccess('authorization');
        this.props.aircraftFlightTypePilotMapQuery.refetch();
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
    this.setState({
      isEditing: false,
      editTitle: '',
      aircraft: null,
      flightType: null,
      pilotIds: [],
    });
  }

  renderAircraftFlightTypePilotsTd(aircraft, flightType, aircraftFlightTypePilots) {
    const id = `${aircraft.id}-${flightType.id}`;
    if (aircraftFlightTypePilots) {
      const pilotIds = aircraftFlightTypePilots.pilots.map((pilot) => pilot.pilot_id);
      const pilotNames = this.props.pilotListQuery.data
        .filter((model) => pilotIds.indexOf(model.id) > -1)
        .map((model) => model.fullName)
        .join(', ');
      const pilotCount = pilotIds.length;
      const tooltip = <Tooltip id={`tooltip-${id}`}>{pilotNames}</Tooltip>;
      return (
        <td key={`td-${id}`}>
          <OverlayTrigger overlay={tooltip} placement="top">
            <Button
              id={`edit-${id}`}
              data-aircraft-id={aircraft.id}
              data-flight-type-id={flightType.id}
              data-pilot-ids={JSON.stringify(pilotIds)}
              type="button"
              variant="dark"
              onClick={this.handleEditClick}
              className="p-1"
            >
              <Badge variant="light">{pilotCount}</Badge>
            </Button>
          </OverlayTrigger>
        </td>
      );
    }
    return (
      <td key={`td-${id}`}>
        <Button
          id={`edit-${id}`}
          data-aircraft-id={aircraft.id}
          data-flight-type-id={flightType.id}
          data-pilot-ids={JSON.stringify([])}
          style={{ opacity: '0.5' }}
          type="button"
          variant="dark"
          onClick={this.handleEditClick}
          className="p-1"
        >
          <Badge variant="light">0</Badge>
        </Button>
      </td>
    );
  }

  renderAircraftFlightTypePilotTd(aircraft, flightType, aircraftFlightTypePilots) {
    const id = `${aircraft.id}-${flightType.id}`;
    const pilotId = this.state.filterPilotId;
    const pilotIds = aircraftFlightTypePilots
      ? aircraftFlightTypePilots.pilots.map((pilot) => pilot.pilot_id)
      : [];
    const namedPilotsOnly = aircraft.named_pilots_only;
    const namedPilotIds = aircraft.namedPilots.map((np) => np.id);
    if (namedPilotsOnly && !includes(namedPilotIds, pilotId)) {
      return (
        <td key={`td-${id}`} className="text-center" style={{ color: '#777777' }}>
          x
        </td>
      );
    }
    const pilotChange = get(this.state.pilotChanges, [
      pilotId,
      aircraft.id,
      flightType.id,
    ]);
    if (
      (pilotChange && includes(pilotChange, pilotId)) ||
      (!pilotChange && includes(pilotIds, pilotId))
    ) {
      return (
        <td key={`td-${id}`}>
          <Button
            style={{ padding: '0', color: '#777777' }}
            id={`edit-${id}`}
            data-aircraft-id={aircraft.id}
            data-flight-type-id={flightType.id}
            data-pilot-ids={JSON.stringify(without(pilotIds, pilotId))}
            type="button"
            variant="light"
            onClick={this.handleEditPilotClick}
          >
            <Glyphicon glyph="check" />
          </Button>
        </td>
      );
    }
    return (
      <td key={`td-${id}`}>
        <Button
          style={{ padding: '0', color: '#777777' }}
          id={`edit-${id}`}
          data-aircraft-id={aircraft.id}
          data-flight-type-id={flightType.id}
          data-pilot-ids={JSON.stringify([...pilotIds, pilotId])}
          type="button"
          variant="light"
          onClick={this.handleEditPilotClick}
        >
          <Glyphicon glyph="unchecked" />
        </Button>
      </td>
    );
  }

  renderAircraftFlightTypePilot(aircraft, flightType, aircraftFlightTypePilots) {
    const id = `${aircraft.id}-${flightType.id}`;
    const flightTypeIds = aircraft.flightTypes.map((ft) => ft.id);
    if (flightTypeIds.indexOf(flightType.id) > -1) {
      if (this.state.filterPilotId) {
        return this.renderAircraftFlightTypePilotTd(
          aircraft,
          flightType,
          aircraftFlightTypePilots
        );
      }
      return this.renderAircraftFlightTypePilotsTd(
        aircraft,
        flightType,
        aircraftFlightTypePilots
      );
    }
    return (
      <td key={`td-${id}`} className="text-center" style={{ color: '#777777' }}>
        -
      </td>
    );
  }

  renderData() {
    if (this.isLoaded() || this.state.isFirstLoaded) {
      return (
        <div>
          <AircraftFlightTypePilot
            pilots={this.props.pilotListQuery.data}
            pilotIds={this.state.pilotIds}
            aircraft={this.state.aircraft}
            isEditing={this.state.isEditing}
            editTitle={this.state.editTitle}
            handleEditCancel={this.handleEditCancel}
            handleEditSubmit={this.handleEditSubmit}
          />
          <Row className="my-4">
            <Col>
              <Title list>Aircraft Flight Type Pilot Authorizations</Title>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12}>
              <Form inline>
                <InputField
                  size="md"
                  labelWidth={0}
                  inputWidth={0}
                  input={{
                    name: 'filterPilotId',
                    value: this.state.filterPilotId,
                    onChange: this.handleDisplayPilotIdChange,
                  }}
                  asElement="select"
                  selectOptions={this.props.pilotListQuery.data}
                  optionKey="fullName"
                  defaultSelectOptionName="All"
                />
                <Button
                  type="button"
                  variant="primary"
                  disabled={
                    this.props.currentSettingsMutating || !this.state.filterPilotId
                  }
                  onClick={this.handleEditPilotSubmit}
                  className="ml-2"
                >
                  {this.state.filterPilotId && this.props.currentSettingsMutating ? (
                    <Glyphicon glyph="refresh" spin />
                  ) : (
                    ''
                  )}
                  Save Pilot
                </Button>
              </Form>
            </Col>
          </Row>
          <Row className="mb-4" />
          <table className="table table-condensed table-header-rotated" size="sm">
            <thead>
              <tr>
                <th>&nbsp;</th>
                {this.props.flightTypeListQuery.data.map((flightType) => (
                  <th key={`flight_type_id_${flightType.id}`} className="rotate-45">
                    <div>
                      <span>{flightType.name}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.aircraftListQuery.data.map((aircraft) => {
                const aircraftFlightTypes =
                  this.props.aircraftFlightTypePilotMapQuery.data.find(
                    (aftpm) => aftpm.aircraft_id === aircraft.id
                  );
                return (
                  <tr key={`aircraft_id_${aircraft.id}`}>
                    <td className="row-header">{aircraft.registration_abbreviated}</td>
                    {this.props.flightTypeListQuery.data.map((flightType) => {
                      const aircraftFlightTypePilots = aircraftFlightTypes
                        ? aircraftFlightTypes.flight_types.find(
                            (aft) => aft.flight_type_id === flightType.id
                          )
                        : null;
                      return this.renderAircraftFlightTypePilot(
                        aircraft,
                        flightType,
                        aircraftFlightTypePilots
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return undefined;
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsReportPilotId: state.currentSettings.reportPilotId,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  graphql(aircraftFlightTypePilotsMutation, {
    name: 'aircraftFlightTypePilotsMutation',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(flightTypeListQuery, {
    name: 'flightTypeListQuery',
  }),
  graphql(contactItemListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(aircraftFlightTypePilotMapQuery, {
    name: 'aircraftFlightTypePilotMapQuery',
    options: () => ({ fetchPolicy: 'cache-and-network' }),
  })
)(AuthorizationList);
