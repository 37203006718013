const Title = ({ children, form, updating, list, show, fullName, id, noNew }) => (
  <>
    {form && updating && <h2 className="my-4">Edit {children}</h2>}
    {form && !updating && !noNew && <h2 className="my-4">New {children}</h2>}
    {form && !updating && noNew && <h2 className="my-4">{children}</h2>}

    {list && <h3>{children}</h3>}
    {show && fullName && (
      <h3>
        {children} Details for {fullName}
      </h3>
    )}
    {show && id && (
      <h3 className="my-4">
        {children} Details for #{id}
      </h3>
    )}
  </>
);

export default Title;
