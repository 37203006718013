import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash/get';
import pick from 'lodash/pick';

import ContactFormAddressFields from './contact_form_address_fields';
import Glyphicon from '../glyphicon';

class ContactFormAddressFieldArray extends Component {
  constructor(props) {
    super(props);
    this._handleAddButtonClick = this._handleAddButtonClick.bind(this);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  _handleAddButtonClick() {
    this.props.addresses.fields.push({
      address_type: 'home',
    });
  }

  _handleDeleteClick(index) {
    const addressValues = get(this.props, [
      'formValues',
      this.props.addresses.fields.name,
      index,
    ]);
    if (get(addressValues, 'id')) {
      this.props.change(
        [this.props.addresses.fields.name, index, '_destroy'].join('.'),
        true
      );
    } else {
      this.props.addresses.fields.remove(index);
    }
  }

  _renderAddressList() {
    const formKeys = ['id'];
    return (
      <Col sm={{ span: 9, offset: 3 }}>
        {this.props.addresses.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}.address_type`,
              `${field}.description`,
              `${field}.prefix`,
              `${field}.street_number`,
              `${field}.suffix`,
              `${field}.street`,
              `${field}.suburb`,
              `${field}.city`,
              `${field}.region`,
              `${field}.postcode`,
              `${field}.post_office_box`,
            ];
            return (
              <Fields
                key={`ad-${index}`}
                index={index}
                parentField={field}
                names={fields}
                component={ContactFormAddressFields}
                formValues={pick(values, formKeys)}
                handleDeleteClick={this._handleDeleteClick}
                addressTypes={this.props.addressTypes}
              />
            );
          }
        })}
      </Col>
    );
  }

  render() {
    return (
      <Row>
        {this._renderAddressList()}
        <Col sm={{ span: 9, offset: 3 }}>
          <Button type="button" variant="dark" onClick={this._handleAddButtonClick}>
            <Glyphicon glyph="plus" />
            Add Address
          </Button>
        </Col>
      </Row>
    );
  }
}

export default ContactFormAddressFieldArray;
