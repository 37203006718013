import { Row, Col, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Fields } from 'redux-form';

import get from 'lodash/get';
import pick from 'lodash/pick';
import Glyphicon from '../glyphicon';

import LocationFormFuelBowserFields from './location_form_fuel_bowser_fields';

class LocationFormFuelBowserFieldArray extends Component {
  constructor(props) {
    super(props);
    this._handleAddButtonClick = this._handleAddButtonClick.bind(this);
    this._handleDeleteClick = this._handleDeleteClick.bind(this);
  }

  _handleAddButtonClick() {
    this.props.fuelBowsers.fields.push({});
  }

  _handleDeleteClick(index) {
    const fuelBowserValues = get(this.props, [
      'formValues',
      this.props.fuelBowsers.fields.name,
      index,
    ]);
    if (get(fuelBowserValues, 'id')) {
      this.props.change(
        [this.props.fuelBowsers.fields.name, index, '_destroy'].join('.'),
        true
      );
    } else {
      this.props.fuelBowsers.fields.remove(index);
    }
  }

  _renderList() {
    const formKeys = ['id'];
    return (
      <Col sm={{ span: 9, offset: 3 }}>
        {this.props.fuelBowsers.fields.map((field, index) => {
          const values = get(this.props, `formValues.${field}`, {});
          if (!values._destroy) {
            const fields = [
              `${field}.price`,
              `${field}.fuel_type_id`,
              `${field}.fuel_company_id`,
              `${field}.fuel_availability_id`,
              `${field}.fuel_pump_access_id`,
            ];
            return (
              <Fields
                key={`fb-${index}`}
                index={index}
                parentField={field}
                names={fields}
                component={LocationFormFuelBowserFields}
                formValues={pick(values, formKeys)}
                handleDeleteClick={this._handleDeleteClick}
                fuelAvailabilities={this.props.fuelAvailabilities}
                fuelCompanies={this.props.fuelCompanies}
                fuelPumpAccesses={this.props.fuelPumpAccesses}
                fuelTypes={this.props.fuelTypes}
              />
            );
          }
          return null;
        })}
      </Col>
    );
  }

  render() {
    return (
      <Row>
        {this._renderList()}
        <Col sm={{ span: 9, offset: 3 }}>
          <Button type="button" variant="dark" onClick={this._handleAddButtonClick}>
            <Glyphicon glyph="plus" />
            Add Fuel Bowser
          </Button>
        </Col>
      </Row>
    );
  }
}

export default LocationFormFuelBowserFieldArray;
