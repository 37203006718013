import { Col, Row, Modal, Button, Form } from 'react-bootstrap';
import { Component } from 'react';

import _get from 'lodash/get';

class AircraftFlightTypePilot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pilotIds: [],
    };
    this._handleEditCancel = this._handleEditCancel.bind(this);
    this._handleEditSubmit = this._handleEditSubmit.bind(this);
    this._handleMultipleCheckboxChange = this._handleMultipleCheckboxChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ pilotIds: nextProps.pilotIds });
  }

  _handleEditSubmit() {
    if (this.props.handleEditSubmit) {
      this.props.handleEditSubmit(this.state.pilotIds);
    }
  }

  _handleEditCancel() {
    if (this.props.handleEditCancel) {
      this.props.handleEditCancel();
    }
  }

  _handleMultipleCheckboxChange(e) {
    const ids = [...this.state.pilotIds];
    const id = parseInt(e.target.value);
    if (id && ids) {
      const index = ids.indexOf(id);
      if (e.target.checked) {
        if (index === -1) {
          this.setState({
            pilotIds: [id, ...ids],
          });
        }
      } else if (index > -1) {
        ids.splice(index, 1);
        this.setState({
          pilotIds: ids,
        });
      }
    }
  }

  renderForm() {
    const namedPilotsOnly = this.props.aircraft && this.props.aircraft.named_pilots_only;
    const namedPilotIds = _get(this.props, 'aircraft.namedPilots', []).map((np) => np.id);
    return (
      <Form onSubmit={this._handleEditSubmit}>
        <Form.Group controlId="pilot_ids">
          <Form.Label>
            {namedPilotsOnly
              ? 'Authorized Pilots (select from aircraft named list only)'
              : 'Authorized Pilots (select from all pilots)'}
          </Form.Label>
          <Row>
            {this.props.pilots
              .filter((model) =>
                namedPilotsOnly ? namedPilotIds.includes(model.id) : model
              )
              .map((model) => {
                const { id, display_name: displayName } = model;
                return (
                  <Col sm={4} key={id}>
                    <Form.Check
                      id={id}
                      type="checkbox"
                      value={id}
                      checked={this.state.pilotIds.indexOf(id) > -1}
                      onChange={this._handleMultipleCheckboxChange}
                      label={displayName}
                    />
                  </Col>
                );
              })}
          </Row>
        </Form.Group>
      </Form>
    );
  }

  render() {
    const { isEditing, editTitle } = this.props;
    return (
      <Modal show={isEditing} onHide={this._handleEditCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{editTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderForm()}</Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="danger" onClick={this._handleEditCancel}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={this._handleEditSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AircraftFlightTypePilot;
