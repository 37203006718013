/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DateTime from 'react-datetime';
import moment from 'moment';

import InputField from './input_field';
import { isInvalid, validationText } from './helpers';
import HelpBlock from './help_block';

moment.updateLocale('en-nz');

const ReactDateTimeField = (props) => {
  const {
    input,
    // meta,
    children,
    size,
    labelWidth,
    inputWidth,
    helpText,
    dateFormat,
    timeFormat,
    closeOnSelect,
    inputStyle,
  } = props;

  const [fieldFormat, setFieldFormat] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const [fieldMeta, setFieldMeta] = useState({});

  useEffect(() => {
    const newFieldFormat = [dateFormat, timeFormat].filter((format) => format).join(' ');
    setFieldFormat(newFieldFormat);
  }, [dateFormat, timeFormat]);

  useEffect(() => {
    const newFieldValue =
      fieldFormat && input.value ? moment(input.value).format(fieldFormat) : '';
    setFieldValue(newFieldValue);
    setFieldMeta({});
  }, [fieldFormat, input.value]);

  const handleInputFieldChange = (date) => {
    if (moment.isMoment(date)) {
      // todo this needs work for fields with times.  Perhaps a switch statement bases on the fieldFormat?
      setFieldMeta({});
      const newDate = date.clone().startOf('day');
      input.onChange(newDate.format());
    } else {
      setFieldMeta({
        touched: true,
        invalid: true,
        error: helpText,
      });
      setFieldValue(date);
    }
  };

  const invalid = isInvalid(fieldMeta);

  return (
    <InputField
      size={size}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      innerContent={
        <>
          <DateTime
            value={fieldValue}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            onChange={handleInputFieldChange}
            closeOnSelect={closeOnSelect}
            inputProps={{
              className: `form-control form-control-${size} ${invalid && 'is-invalid'}`,
            }}
            {...(invalid && { className: 'is-invalid' })}
            {...(inputStyle && { style: inputStyle })}
          />
          {invalid && (
            <Form.Control.Feedback type="invalid">
              {validationText(fieldMeta, helpText)}
            </Form.Control.Feedback>
          )}
          {!invalid && helpText && <HelpBlock>{helpText}</HelpBlock>}
        </>
      }
    >
      {children}
    </InputField>
  );
};

ReactDateTimeField.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 3,
  helpText: '',
  dateFormat: false,
  timeFormat: false,
  closeOnSelect: false,
};

export default ReactDateTimeField;
