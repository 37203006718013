import { Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import L from 'leaflet';

import _get from 'lodash/get';
import _merge from 'lodash/merge';

const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiZ29yZG9ua2luZzAyIiwiYSI6ImNpbnUxbWJ3NjExZ2x1aWtqaThraGM3dmsifQ.rKb2iQMCm5ZPhJGfsSg_mg';

const baseLayerTemplates = [
  {
    name: 'Mapbox - Satellite Streets',
    maptype: 'mapbox/satellite-streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Street',
    maptype: 'mapbox/streets-v11',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  {
    name: 'Mapbox - Satellite',
    maptype: 'mapbox/satellite-v9',
    url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
];

class LocationFormMapFields extends Component {
  constructor(props) {
    super(props);

    this._mountMap = this._mountMap.bind(this);
    this._handleMarkerDragend = this._handleMarkerDragend.bind(this);
    this._handleMapZoomend = this._handleMapZoomend.bind(this);

    this.map = null;
    this.marker = null;
    this.position = [-36.8484, 174.7622];
    this.zoom = 15;
  }

  UNSAFE_componentWillMount() {
    this._setGeometry(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      _get(this.props, 'formValues.latitude') !==
        _get(nextProps, 'formValues.latitude') ||
      _get(this.props, 'formValues.longitude') !== _get(nextProps, 'formValues.longitude')
    ) {
      this._setGeometry(nextProps);
    }
  }

  shouldComponentUpdate() {
    // no need to rerender if map is laid down.
    return !this.map;
  }

  createBaseLayer = (baseLayer) => {
    const { maptype, url, attribution } = baseLayer;
    const element = L.tileLayer(url, {
      attribution,
      id: maptype,
      accessToken: MAPBOX_API_TOKEN,
      tileSize: 512,
      zoomOffset: -1,
    });
    return Object.assign({}, baseLayer, { element });
  };

  _setGeometry(props) {
    const { latitude, longitude } = _get(props, 'formValues');
    this.position = [latitude, longitude];
    this._setMap();
  }

  _setMap() {
    if (this.map) {
      this.map.setView(this.position, this.zoom);
      this.marker.setLatLng(this.position);
    }
  }

  _mountMap(ref) {
    if (ref) {
      this.map = L.map('location-form-map', {
        zoom: this.zoom,
        center: this.position,
      }).on('zoomend', this._handleMapZoomend);

      this.baseLayers = baseLayerTemplates.map((baseLayer) =>
        this.createBaseLayer(baseLayer)
      );
      const baseLayersMenu = this.baseLayers.reduce(
        (result, layer) => _merge({}, result, { [layer.name]: layer.element }),
        {}
      );
      L.control.layers(baseLayersMenu, {}, { position: 'topright' }).addTo(this.map);
      baseLayersMenu[this.baseLayers[0].name].addTo(this.map);

      this.marker = L.marker(this.position, { draggable: true })
        .on('dragend', this._handleMarkerDragend)
        .addTo(this.map);
    } else {
      this.map.remove();
      this.map = null;
      this.marker = null;
      this.baseLayers = null;
    }
  }

  _handleMarkerDragend(e) {
    const { lat, lng } = e.target.getLatLng();
    this.position = [lat, lng];
    this.props.latitude.input.onChange(lat);
    this.props.longitude.input.onChange(lng);
  }

  _handleMapZoomend(e) {
    this.zoom = e.target.getZoom();
  }

  render() {
    return (
      <Col xs={12}>
        <Row>
          <Col sm={{ span: 9, offset: 3 }}>
            <div
              id="location-form-map"
              style={{ height: '500px' }}
              ref={(input) => {
                this._mountMap(input);
              }}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}

export default LocationFormMapFields;
