import { Component } from 'react';
import ReactTags from 'react-tag-autocomplete';

import find from 'lodash/find';
import clone from 'lodash/clone';
import snakeCase from 'lodash/snakeCase';

class TagList extends Component {
  constructor(props) {
    super(props);
    this.onTagDelete = this.onTagDelete.bind(this);
    this.onTagAdd = this.onTagAdd.bind(this);
  }

  onTagDelete(i) {
    const cloneTagCollection = clone(this.props.tagCollectionInput.value);
    const deletedTag = cloneTagCollection.splice(i, 1);
    const tagCollection = cloneTagCollection.map(({ text }) => ({
      id: snakeCase(text),
      text,
    }));
    if (this.props.onTagDelete) {
      this.props.onTagDelete(deletedTag[0].text);
    }
    this.props.tagCollectionInput.onChange(tagCollection);
  }

  onTagAdd(tag) {
    const { name: text } = tag;
    if (!find(this.props.tagCollectionInput.value, { text })) {
      const cloneTagCollection = clone(this.props.tagCollectionInput.value);
      cloneTagCollection.push({ id: snakeCase(text), text });
      if (this.props.onTagCreate) {
        this.props.onTagCreate(text);
      }
      this.props.tagCollectionInput.onChange(cloneTagCollection);
    }
  }

  render() {
    const { suggestions, tagCollectionInput } = this.props;
    return (
      <ReactTags
        placeholderText={
          this.props.placeholderText ? this.props.placeholderText : 'Add new tag'
        }
        suggestions={suggestions.map(({ id, text }) => ({ id, name: text }))}
        tags={tagCollectionInput.value.map(({ id, text }) => ({ id, name: text }))}
        onDelete={this.onTagDelete}
        onAddition={this.onTagAdd}
        allowBackspace={false}
        allowNew={this.props.suggestionsOnly ? this.props.suggestionsOnly : true}
      />
    );
  }
}
export default TagList;
