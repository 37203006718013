import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';

import thunkMiddleware from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';

import { reducers, initialState } from '../reducers';

const history = createBrowserHistory();

const rootReducer = combineReducers({
  ...reducers,
  form: formReducer,
});

const persistConfig = {
  key: 'saam210417',
  storage,
  whitelist: ['currentSettings', 'currentContact', 'currentTenant', 'tags'],
};

const reducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

const persistor = persistStore(store);

export { history, store, persistor };
