import { Badge as BSBadge } from 'react-bootstrap';

const Badge = ({ filtered, children }) => (
  <h4 className="mt-1 mx-2">
    <BSBadge pill variant="danger" style={filtered ? {} : { opacity: '0.5' }}>
      {children}
    </BSBadge>
  </h4>
);

export default Badge;
