import moment from 'moment';
import actionTypes from '../constants';

import { reset, loadSuccess, loadFailure, rehydrate } from '../lib/reducer_helpers';

const dataKey = 'currentSettings';

moment.updateLocale('en-nz');

export const initialState = {
  versionCurrent: true,
  bookingRequestedCalendarType: 'flight',
  bookingCollectionView: 'resourceTimelineDay',
  bookingCollectionStartDate: moment().startOf('day').format(),
  bookingCollectionEndDate: moment().endOf('day').format(),
  mutating: false,
  lastMutation: { type: '', id: '', timestamp: '' },
  lastMutationProcessed: moment().toISOString(),
  cacheUpdatedAt: '',
  statutory_roles: [],
  booking_statuses: [],
  booking_status_cancelled: 'cancelled',
  address_types: [],
  pilot_flight_log_flight_types: [],
  phone_number_types: [],
  location_display_formats: [],
  location_default_display_format_id: 1,
  engine_event_types: [],
  // returnRoute: '/bookings',
  returnRoute: '/locations',
  salutations: [],
  check_category_checkable_groups: {},
  booking_calendar_types: [],
  booking_calendar_type_banner: 'banner',
  booking_calendar_type_flight: 'flight',
  pilot_flight_expense_overnight_text: '',
  pilot_flight_expense_overnight_rate: '',
  fuel_bowser_fill_default_quantity_unit: '',
  wb_limit_limit_types: [],
  wb_limit_limit_type_lateral: '',
  wb_limit_limit_type_longitudinal: '',
  aircraft_seat_seat_types: [],
  aircraft_type_arm_units: [],
  aircraft_type_weight_units: [],
  aircraft_type_fuel_units: [],
  reportStartDate: moment().startOf('month').toISOString(),
  reportAircraftId: '',
  reportFuelTankerId: '',
  reportLocationId: '',
  reportPilotId: '',
  reportChargeableId: '',
  reportProviderId: '',
  reportStart: moment().startOf('month').toISOString(),
  reportEnd: moment().endOf('month').toISOString(),
  filterCheckCategoryId: '',
  filterRoleId: '',
  online: false,
  deviceOnline: false,
  serverOnline: false,
  containerWidth: 0,
  containerHeight: 0,
  bookingAircraftGroupIds: [1],
  enteredAircraftGroupIds: [1],
};

export default function currentSettingsReducer(state = initialState, action) {
  const { type, payload, error } = action;
  if (error) {
    return state;
  }
  switch (type) {
    case 'persist/REHYDRATE':
      return rehydrate(initialState, (payload || {})[dataKey] || {}, {
        versionCurrent: true,
        mutating: false,
      });
    case actionTypes.DATA_RESET:
      return reset({
        ...initialState,
        ...{
          containerWidth: state.containerWidth,
          containerHeight: state.containerHeight,
        },
      });
    case actionTypes.DATA_LOAD_SUCCESS:
      return loadSuccess(
        {
          ...initialState,
          ...{
            containerWidth: state.containerWidth,
            containerHeight: state.containerHeight,
          },
        },
        payload[dataKey],
        false
      );
    case actionTypes.DATA_LOAD_FAILURE:
      return loadFailure(initialState);
    case actionTypes.CURRENT_SETTINGS_SET:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.MUTATION_CREATE:
      return {
        ...state,
        lastMutation: payload,
      };
    case actionTypes.MUTATION_UPDATE:
      return {
        ...state,
        lastMutation: payload,
      };
    case actionTypes.MUTATION_DESTROY:
      return {
        ...state,
        lastMutation: payload,
      };
    default:
      return state;
  }
}
