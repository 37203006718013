import { Form, Col, Row } from 'react-bootstrap';

import clone from 'lodash/clone';
import upperFirst from 'lodash/upperFirst';

import { isInvalid, validationText } from './helpers';
import InputField from './input_field';
import HelpBlock from './help_block';

const CheckboxMultipleInputField = (props) => {
  const {
    children,
    input,
    meta,
    candidateList,
    selectedIds,
    labelWidth,
    inputWidth,
    inputItemWidth,
    inlineLabel,
    size,
    helpText,
  } = props;

  const invalid = isInvalid(meta);

  const humanize = (string) => upperFirst(string.split('_').join(' '));

  const handleCheckboxChange = (e) => {
    const ids = clone(selectedIds);
    let id = null;
    if (Number.isNaN(e.target.getAttribute('data-id'))) {
      id = e.target.getAttribute('data-id');
    } else {
      id = parseInt(e.target.getAttribute('data-id'), 10);
    }
    if (id) {
      const idIndex = ids.indexOf(id);
      if (e.target.checked) {
        if (idIndex === -1) {
          input.onChange([id, ...ids]);
        }
      } else if (idIndex > -1) {
        ids.splice(idIndex, 1);
        input.onChange(ids);
      }
    }
  };

  return (
    <InputField
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      innerContent={
        <Row>
          {candidateList.map((item) => {
            const { id, name, fullName } = item;
            const labelName = name || fullName || 'UNKNOWN LABEL';
            return (
              <Col sm={inputItemWidth} key={id}>
                <Form.Check>
                  <Form.Check.Input
                    id={`${input.name}-${id}`}
                    data-id={id}
                    type="checkbox"
                    checked={selectedIds.indexOf(id) > -1}
                    value={id}
                    onChange={handleCheckboxChange}
                    isInvalid={invalid}
                  />
                  {inlineLabel && (
                    <Form.Check.Label
                      className={`py-0 col-form-label-${size}`}
                      htmlFor={`${input.name}-${id}`}
                    >
                      {humanize(labelName)}
                    </Form.Check.Label>
                  )}
                </Form.Check>
              </Col>
            );
          })}
          <Col sm={12}>
            {invalid && (
              <Form.Control.Feedback type="invalid">
                {validationText(meta, helpText)}
              </Form.Control.Feedback>
            )}
            {!invalid && helpText && <HelpBlock>{helpText}</HelpBlock>}
          </Col>
        </Row>
      }
    >
      {children}
    </InputField>
  );
};

CheckboxMultipleInputField.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 3,
  inputItemWidth: 4,
  helpText: '',
  inlineLabel: true,
};

export default CheckboxMultipleInputField;
