/* eslint-disable react/jsx-props-no-spreading */
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Component } from 'react';
import BlurOnlyFormControl from './blur_only_form_control';
import HelpBlock from './help_block';
import { isInvalid, validationText } from './helpers';

class InputField extends Component {
  renderFormControl(invalid) {
    const {
      blurOnly,
      noTab,
      placeholder,
      type,
      input,
      inputStyle,
      asElement,
      defaultSelectOption,
      defaultSelectOptionName,
      selectOptions,
      optionKey,
      rows,
      plainText,
      readOnly,
      customOnFocus,
      customOnBlur,
      customOnChange,
      showValid,
      showInvalid,
      size,
    } = this.props;

    const onFocus = customOnFocus
      ? customOnFocus(input.name, input.onFocus)
      : input.onFocus;
    const onBlur = customOnBlur ? customOnBlur(input.name, input.onBlur) : input.onBlur;
    const onChange = customOnChange
      ? customOnChange(input.name, input.onChange)
      : input.onChange;

    const commonProps = {
      size,
      type,
      onFocus,
      ...(type !== 'file' && { value: input.value }),
      ...(placeholder && { placeholder }),
      ...(asElement && { as: asElement }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
      ...(showInvalid && { isInvalid: invalid }),
      ...(showValid && { isValid: !invalid }),
      ...(readOnly && { readOnly }),
    };

    if (plainText) {
      return (
        <Form.Control
          plaintext
          readOnly
          value={input.value}
          className={`form-control-${size}`}
          {...(inputStyle && { style: inputStyle })}
        />
      );
    }
    if (selectOptions) {
      return (
        <Form.Control {...commonProps} onChange={onChange} as={asElement}>
          {defaultSelectOption ? (
            <option key={0} value="">
              {defaultSelectOptionName}
            </option>
          ) : (
            ''
          )}
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option[optionKey]}
            </option>
          ))}
        </Form.Control>
      );
    }
    if (blurOnly) {
      return <BlurOnlyFormControl {...commonProps} onBlur={onBlur} />;
    }

    return <Form.Control {...commonProps} onBlur={onBlur} onChange={onChange} />;
  }

  renderInnerContent() {
    const {
      meta,
      size,
      labelWidth,
      helpText,
      helpBlock,
      preAddon: prepend,
      postAddon: append,
      children,
    } = this.props;

    const invalid = isInvalid(meta);

    return (
      <>
        {labelWidth === 0 && children && (
          <Form.Label className={`col-form-label col-form-label-${size}`}>
            {children}
          </Form.Label>
        )}
        {prepend || append ? (
          <InputGroup size={size}>
            {prepend && (
              <InputGroup.Prepend>
                <InputGroup.Text>{prepend}</InputGroup.Text>
              </InputGroup.Prepend>
            )}
            {this.renderFormControl(invalid)}
            {append && (
              <InputGroup.Append>
                <InputGroup.Text>{append}</InputGroup.Text>
              </InputGroup.Append>
            )}
            {helpBlock && invalid && (
              <Form.Control.Feedback type="invalid">
                {validationText(meta, helpText)}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        ) : (
          <>
            {this.renderFormControl(invalid)}
            {helpBlock && invalid && (
              <Form.Control.Feedback type="invalid">
                {validationText(meta, helpText)}
              </Form.Control.Feedback>
            )}
          </>
        )}
        {helpBlock && helpText && !invalid && <HelpBlock>{helpText}</HelpBlock>}
      </>
    );
  }

  render() {
    const {
      input,
      groupStyle,
      groupClassName,
      labelWidth,
      inputWidth,
      size,
      children,
      innerContent,
    } = this.props;
    return (
      <Form.Group
        controlId={input.name}
        {...(groupStyle && { style: groupStyle })}
        {...(groupClassName && { className: groupClassName })}
        {...(labelWidth > 0 && { as: Row })}
        {...(labelWidth === 0 && inputWidth > 0 && { as: Col, sm: inputWidth })}
      >
        {labelWidth > 0 ? (
          <>
            <Form.Label
              column={size || true}
              sm={labelWidth}
              className="d-flex justify-content-end"
            >
              {children}
            </Form.Label>
            <Col sm={inputWidth}>{innerContent || this.renderInnerContent()}</Col>
          </>
        ) : (
          innerContent || this.renderInnerContent()
        )}
      </Form.Group>
    );
  }
}

InputField.defaultProps = {
  size: 'sm',
  labelWidth: 3,
  inputWidth: 9,
  defaultSelectOption: true,
  selectOptions: false,
  helpText: '',
  helpBlock: true,
  plainText: false,
  showValid: false,
  showInvalid: true,
  optionKey: 'name',
  defaultSelectOptionName: '',
};

export default InputField;
