import { ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Glyphicon from '../glyphicon';
import Confirm from '../confirm';

const FormButtons = (props) => {
  const {
    submitting,
    pristine,
    updating,
    completed,
    cancelLink,
    handleSubmit,
    handleCompleteSubmit,
    handleRolloverSubmit,
    name,
  } = props;
  return (
    <ButtonToolbar className="d-flex justify-content-end">
      <ButtonGroup className="mr-1">
        <LinkContainer to={cancelLink}>
          <Button type="reset" variant="danger" disabled={submitting}>
            Cancel
          </Button>
        </LinkContainer>
      </ButtonGroup>
      {updating && name && (
        <ButtonGroup className="mx-1">
          {handleCompleteSubmit && (
            <Confirm
              disabled={completed}
              onConfirm={handleSubmit(handleCompleteSubmit)}
              title={`Complete ${name}`}
              body={`Are you sure you want to complete this ${name}? It will be marked completed but NOT rolled over `}
              confirmText="Complete"
            >
              <Button type="button" variant="primary" disabled={submitting}>
                Complete
              </Button>
            </Confirm>
          )}
          {handleRolloverSubmit && (
            <Confirm
              disabled={completed}
              onConfirm={handleSubmit(handleRolloverSubmit)}
              title={`Rollover ${name}`}
              body={`Are you sure you want to rollover this ${name}? It will be marked completed AND rolled over `}
              confirmText="Rollover"
            >
              <Button type="button" variant="primary" disabled={submitting}>
                Rollover
              </Button>
            </Confirm>
          )}
        </ButtonGroup>
      )}
      <ButtonGroup className="ml-1">
        <Button type="submit" variant="primary" disabled={pristine || submitting}>
          {submitting ? <Glyphicon glyph="refresh" spin /> : ''}
          {updating ? 'Update' : 'Create'}
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  );
};

export default FormButtons;
