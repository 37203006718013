/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import { Component } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
  Form,
  Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';

import _debounce from 'lodash/debounce';
import _upperFirst from 'lodash/upperFirst';

import { storeReload } from '../actions/store_actions';

import { suggestionsFilter } from '../actions/suggestion_actions';

class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: '',
    };
    this.handleStoreReload = this.handleStoreReload.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onChange = this.onChange.bind(this);
    this.isFlight = /^flight/.test(window.location.hostname);
  }

  UNSAFE_componentWillMount() {
    this.delayedOnSuggestionsFetchRequested = _debounce((value) => {
      this.getSuggestions(value);
    }, 500);
  }

  componentWillUnmount() {
    this.delayedOnSuggestionsFetchRequested.cancel();
  }

  onChange(event, { newValue, method }) {
    if (method === 'type') {
      this.setState({
        value: newValue,
      });
    }
  }

  onSuggestionsFetchRequested({ value }) {
    this.delayedOnSuggestionsFetchRequested(value);
  }

  // eslint-disable-next-line no-useless-return
  onSuggestionsClearRequested = () => undefined;

  onSuggestionSelected(event, { suggestion }) {
    this.setState({
      value: '',
    });
    const { id, klass } = suggestion;
    const path = `/${klass}s/${id}`;
    window.open(path, _upperFirst(klass) + id, 'width=1200, height=800');
  }

  getSuggestions(value) {
    this.props.suggestionsFilter({ q: value });
  }

  getSuggestionValue = (suggestion) => suggestion.name;

  renderAdminLink = () => {
    const tenant = window.location.hostname.split('.')[0];
    if (process.env.NODE_ENV === 'development') {
      return `http://${tenant}.lvh.me:1527/admin`;
    }
    return `https://${tenant}.rails.saam.co.nz/admin`;
  };

  reloadPage = () => {
    window.location.reload(true);
  };

  isEmployee() {
    return this.props.currentContact['employee?'];
  }

  handleStoreReload() {
    this.props.storeReload();
  }

  renderSuggestion = (suggestion) => {
    const icon = ((klass) => {
      switch (klass) {
        case 'contact':
          return 'user';
        case 'location':
          return 'mountains';
        default:
          return 'glass';
      }
    })(suggestion.klass);

    return (
      <div>
        <span className={`glyphicons glyphicons-${icon} mr-2`} />
        <span>{suggestion.name}</span>
      </div>
    );
  };

  render() {
    const inputProps = {
      placeholder: 'search...',
      value: this.state.value,
      onChange: this.onChange,
      style: { width: '210px' },
    };

    return (
      <Navbar
        defaultExpanded
        expand="xl"
        style={{ borderColor: '#eeeeee' }}
        variant="light"
        bg="light"
      >
        <Navbar.Brand>
          <Link to="/">{this.isFlight ? 'SAAM FLIGHT' : 'SAAM POLICE'}</Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
          <Nav>
            <Nav.Item className="mr-2">
              <LinkContainer to={{ pathname: '/bookings' }}>
                <Nav.Link eventKey={2}>Bookings</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            {this.isEmployee() && (
              <Nav.Item className="mr-2">
                <LinkContainer to={{ pathname: '/locations' }}>
                  <Nav.Link eventKey={3}>Locations</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            {this.isEmployee() && (
              <Nav.Item className="mr-2">
                <LinkContainer to={{ pathname: '/contacts' }}>
                  <Nav.Link eventKey={4}>Contacts</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            {this.isEmployee() && (
              <NavDropdown title="Other" id="basic-nav-dropdown-other" className="mr-2">
                <LinkContainer to={{ pathname: '/authorizations' }}>
                  <NavDropdown.Item eventKey={5.1}>Authorizations</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/documents' }}>
                  <NavDropdown.Item eventKey={5.2}>Documents</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/checks' }}>
                  <NavDropdown.Item eventKey={5.3}>Checks</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/fuel_tankers' }}>
                  <NavDropdown.Item eventKey={5.4}>Fuel Tankers</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/locations/merge' }}>
                  <NavDropdown.Item eventKey={5.5}>Merge Locations</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/pilot_duty_record' }}>
                  <NavDropdown.Item eventKey={5.6}>Pilot Duty Records</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
            {this.isEmployee() && (
              <NavDropdown title="Weight & Balance" id="basic-nav-dropdown-weight">
                <LinkContainer to={{ pathname: '/aircraft_configurations' }}>
                  <NavDropdown.Item eventKey={9.0}>
                    Aircraft Configurations
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/wb_limits' }}>
                  <NavDropdown.Item eventKey={9.1}>WB Limits</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/seat_configurations' }}>
                  <NavDropdown.Item eventKey={9.2}>Seat Configurations</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/hold_configurations' }}>
                  <NavDropdown.Item eventKey={9.3}>Hold Configurations</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/tank_configurations' }}>
                  <NavDropdown.Item eventKey={9.4}>
                    Fuel Tank Configurations
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
            {this.isEmployee() && (
              <NavDropdown
                title="Reports"
                id="basic-nav-dropdown-reports"
                className="mr-2"
              >
                <LinkContainer to={{ pathname: '/reports/integrity' }}>
                  <NavDropdown.Item eventKey={6.1}>Data Integrity</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/flights/signoff' }}>
                  <NavDropdown.Item eventKey={6.0}>Admin Sign Off</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/aircraft_flight_record' }}>
                  <NavDropdown.Item eventKey={6.3}>
                    Aircraft Flight Record
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/aircraft_flight_time_event' }}>
                  <NavDropdown.Item eventKey={6.4}>
                    Aircraft Daily Hours & Cycles
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to={{ pathname: '/reports/aircraft_flight_type_category' }}
                >
                  <NavDropdown.Item eventKey={6.5}>
                    Aircraft Operating Statistics
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/allocation' }}>
                  <NavDropdown.Item eventKey={6.6}>Allocation</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/provider_flight_hour' }}>
                  <NavDropdown.Item eventKey={6.7}>Flight Hour Matrix</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/chargeable_flight_record' }}>
                  <NavDropdown.Item eventKey={6.8}>
                    Customer Flight Record
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/chargeable_invoice' }}>
                  <NavDropdown.Item eventKey={6.9}>
                    Customer Invoice Report
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/pilot_flight_log' }}>
                  <NavDropdown.Item eventKey={6.1}>Pilot Flight Log</NavDropdown.Item>
                </LinkContainer>
                {/* <LinkContainer to={{ pathname: '/reports/pilot_flight_log_exception' }}>
                  <NavDropdown.Item eventKey={6.71}>
                    Pilot Flight Log Exceptions
                  </NavDropdown.Item>
                </LinkContainer> */}
                <LinkContainer to={{ pathname: '/reports/pilot_flight_expense' }}>
                  <NavDropdown.Item eventKey={6.11}>
                    Pilot Flight Expense
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/pilot_cumulative_hour' }}>
                  <NavDropdown.Item eventKey={6.12}>
                    Pilot Cumulative Hours
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/provider_flight_record' }}>
                  <NavDropdown.Item eventKey={6.13}>
                    Provider Flight Record
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/provider_swap_summary' }}>
                  <NavDropdown.Item eventKey={6.14}>
                    Provider Swap Summary
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/provider_swap_detail' }}>
                  <NavDropdown.Item eventKey={6.15}>
                    Provider Swap Detail
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/location_landing_fee' }}>
                  <NavDropdown.Item eventKey={6.16}>
                    Location Landing Fee
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to={{ pathname: '/reports/fuel_bowser_fill_for_aircraft' }}
                >
                  <NavDropdown.Item eventKey={6.17}>
                    Aircraft Refuel by Bowser
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to={{ pathname: '/reports/fuel_tanker_fill_for_aircraft' }}
                >
                  <NavDropdown.Item eventKey={6.18}>
                    Aircraft Refuel by Tanker
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to={{ pathname: '/reports/fuel_bowser_fill_for_fuel_tanker' }}
                >
                  <NavDropdown.Item eventKey={6.15}>
                    Tanker Refuel by Bowser
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
            <NavDropdown title="Tools" id="basic-nav-dropdown-other" className="mr-2">
              {this.isEmployee() && (
                <NavDropdown.Item
                  eventKey={8.1}
                  target="_blank"
                  href={this.renderAdminLink()}
                >
                  Admin
                </NavDropdown.Item>
              )}
              <NavDropdown.Item eventKey={8.2} href="#" onClick={this.handleStoreReload}>
                Reload
              </NavDropdown.Item>
              <LinkContainer to={{ pathname: '/auth/logout' }}>
                <NavDropdown.Item eventKey={8.3}>Sign Out</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            {this.isFlight && this.isEmployee() && (
              <Nav.Item id="header-safety-link" className="mr-2">
                <Nav.Link
                  target="_blank"
                  eventKey={9}
                  href="https://aviation-sms.knack.com/risk-registers#advanced-flight-registers/"
                >
                  Safety Report
                  <span
                    className="glyphicons glyphicons-new-window"
                    style={{ margin: '-6px' }}
                  />
                </Nav.Link>
              </Nav.Item>
            )}
            {this.props.currentSettingVersionCurrent || (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-header-version-link">
                    Your application version is out of date. Click to refresh your browser
                  </Tooltip>
                }
              >
                <Button variant="danger" onClick={this.reloadPage}>
                  <span
                    className="glyphicons glyphicons-exclamation-sign"
                    style={{ fontSize: '22px' }}
                  />
                </Button>
              </OverlayTrigger>
            )}
          </Nav>
          {this.isEmployee() && (
            <Form inline>
              <Autosuggest
                placeholder="Search"
                suggestions={this.props.suggestionsCollection}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
              />
            </Form>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingVersionCurrent: state.currentSettings.versionCurrent,
    suggestionsCollection: state.suggestions.collection,
    currentContact: state.currentContact,
  };
}

export default connect(mapStateToProps, { suggestionsFilter, storeReload })(Header);
