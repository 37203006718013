/* eslint-disable react/jsx-props-no-spreading */
import { Component } from 'react';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import BlurOnlyFormControl from './blur_only_form_control';
import { isInvalid, validationText } from './helpers';

class RffInputField extends Component {
  renderFormControl(invalid) {
    const {
      blurOnly,
      noTab,
      placeholder,
      input,
      inputStyle,
      asElement,
      defaultSelectOption,
      defaultSelectOptionText,
      selectOptions,
      rows,
      size,
      readOnly,
      disabled,
      plainText,
      customOnChange,
      customOnBlur,
      customOnFocus,
      autoCapitalize,
    } = this.props;
    const onFocus = customOnFocus
      ? customOnFocus(input.name, input.onFocus)
      : input.onFocus;
    const onBlur = customOnBlur ? customOnBlur(input.name, input.onBlur) : input.onBlur;
    const onChange = customOnChange
      ? customOnChange(input.name, input.onChange)
      : input.onChange;

    const commonProps = {
      type: input.type,
      value: input.value,
      onFocus,
      ...(placeholder && { placeholder }),
      ...(asElement && { as: asElement }),
      ...(rows && { rows }),
      ...(readOnly && { readOnly }),
      ...(disabled && { disabled }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
      ...(invalid && { isInvalid: true }),
      ...(size && { size }),
      ...(autoCapitalize && { autoCapitalize }),
    };
    if (plainText) {
      return (
        <Form.Control
          plaintext
          readOnly
          value={input.value}
          {...(inputStyle && { style: inputStyle })}
        />
      );
    }
    if (selectOptions) {
      return (
        <Form.Control {...commonProps} onChange={onChange}>
          {defaultSelectOption ? (
            <option key={0} value="">
              {defaultSelectOptionText || ''}
            </option>
          ) : (
            ''
          )}
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Form.Control>
      );
    }
    if (blurOnly) {
      return <BlurOnlyFormControl {...commonProps} onBlur={onBlur} />;
    }
    return <Form.Control {...commonProps} onBlur={onBlur} onChange={onChange} />;
  }

  render() {
    const {
      input,
      meta,
      groupStyle,
      groupClassName,
      labelWidth,
      inputWidth,
      helpText,
      helpBlock,
      prepend,
      append,
      size,
      children,
    } = this.props;

    const invalid = isInvalid(meta);

    const innerContent = (
      <>
        {labelWidth === 0 && children && <Form.Label>{children}</Form.Label>}
        {prepend || append ? (
          <InputGroup>
            {prepend && (
              <InputGroup.Prepend>
                <InputGroup.Text>{prepend}</InputGroup.Text>
              </InputGroup.Prepend>
            )}
            {this.renderFormControl(invalid)}
            {append && (
              <InputGroup.Append>
                <InputGroup.Text>{append}</InputGroup.Text>
              </InputGroup.Append>
            )}
            {helpBlock && invalid && (
              <Form.Control.Feedback type="invalid">
                {validationText(meta, helpText)}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        ) : (
          <>
            {this.renderFormControl(invalid)}
            {helpBlock && invalid && (
              <Form.Control.Feedback type="invalid">
                {validationText(meta, helpText)}
              </Form.Control.Feedback>
            )}
          </>
        )}
        {helpBlock && helpText && !invalid && (
          <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '80%' }}>
            {helpText}
          </div>
        )}
      </>
    );

    return (
      <Form.Group
        controlId={input.name}
        {...(groupStyle && { style: groupStyle })}
        {...(groupClassName && { className: groupClassName })}
        {...(labelWidth > 0 && { as: Row })}
        {...(labelWidth === 0 && { as: Col })}
        {...(labelWidth === 0 && inputWidth > 0 && { sm: inputWidth })}
      >
        {labelWidth > 0 ? (
          <>
            <Form.Label column={size || true} sm={labelWidth}>
              {children}
            </Form.Label>
            <Col sm={12 - labelWidth}>{innerContent}</Col>
          </>
        ) : (
          innerContent
        )}
      </Form.Group>
    );
  }
}

RffInputField.defaultProps = {
  labelWidth: 0,
  inputWidth: 0,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  plainText: false,
};

export default RffInputField;
