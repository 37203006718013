import { Col, Form } from 'react-bootstrap';

const CheckboxField = (props) => {
  const { input, inline, inputWidth, inputOffset, title } = props;
  return (
    <Col sm={{ span: inputWidth, offset: inputOffset }}>
      <Form.Check
        type="checkbox"
        inline={inline}
        checked={input.value}
        onChange={input.onChange}
        label={title}
      />
    </Col>
  );
};

CheckboxField.defaultProps = {
  inline: false,
  inputWidth: 1,
};

export default CheckboxField;
